import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const ImgWithOrient = ({ aspectRatio, className, ...props }) => {
  let orientation
  if (aspectRatio > 1) orientation = "landscape"
  if (aspectRatio < 1) orientation = "portrait"
  else orientation = "square"

  return <Img className={`${className} ${orientation}`} {...props} />
}

const AboutPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO title="About" keywords={[`blog`, `gatsby`, `javascript`, `react`]} />
      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h3 id="dynamic-styles">About Me</h3>
          <p>
            Hi, I’m Nadeem. I’m a Software Engineer based in NYC. I love to
            create. Previously, I’ve worked at Facebook and Uber.
          </p>
          <p>
            When I’m not building apps, you can catch me running along the
            Hudson, zoned in on a good read, playing guitar with friends,
            setting up for golden hour photoshoots, and whipping up new recipes
            from cookbooks.
          </p>
          <p>
            Recently, I ran the NYC marathon while supporting St. Jude.
            <a href="http://heroes.stjude.org/nadeem">
              [http://heroes.stjude.org/nadeem.]
            </a>
          </p>
          <figure className="kg-card kg-image-card kg-width-wide">
            <Img
              fluid={data.backgroundImage.childImageSharp.fluid}
              className="kg-image"
            />
          </figure>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    backgroundImage: file(relativePath: { eq: "coffee2.jpg" }) {
      childImageSharp {
        fluid {
          aspectRatio
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
)
